<template>
  <no-ssr>
    <form onsubmit="return false;" class="" v-on:keyup.enter="searchProducts">
      <div class="bb-form-group">
        <input
          type="search"
          maxlength="256"
          name="query"
          placeholder="Busca entre más de 10.000 productos"
          id="search"
          v-model="searchQuery"
          autocomplete="off"
          class="bb-form bb-form--lg bb-form--block"
          required
        />
        <div
          class="bb-form-group__ico bb-form-group__ico--lg"
          @click="searchProducts()"
        >
          <img src="../../../assets/img/ico/ico-search.svg" alt="" />
        </div>
      </div>
    </form>
  </no-ssr>
</template>

<script>
import NoSSR from "vue-no-ssr";
import { mapGetters } from "vuex";

export default {
  name: "CatalogueSearchBar",
  data() {
    return {
      searchQuery: "",
    };
  },
  components: {
    "no-ssr": NoSSR,
  },
  computed: {
    ...mapGetters({
      searchState: "searchState",
    }),
  },
  methods: {
    toggleSearch() {
      this.$store.commit("SET_SEARCHSTATE", false);
    },
    searchProducts() {
      if (this.searchQuery === "") return;
      const q = this.searchQuery;
      this.searchQuery = "";
      if (this.$route.fullPath.includes("/resultados")) {
        const parameters = {
          text: q,
          page: 1,
        };
        this.$store.dispatch("getProducts", parameters);
      }
      this.$router
        .push({
          name: "search",
          query: {
            q: q,
          },
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
