<template>
  <div id="catalogue_page">
    <catalogue-header v-if="displayHeader" />
    <catalogue-hero-section />
    <div class="pad-3">
      <div class="bb-container">
        <div class="g-catalogo">
          <catalogue-categories-nav />

          <div class="g-catalogo__list">
            <div class="g-catalogo__title">
              <h3 class="g-catalogo__title-header g-catalogo__title-header--home">
                Lo más incluído en las listas
              </h3>
            </div>
            <catalogue-filters />
            <applied-filters />

            <catalogue-products :parameters="parameters" :route="$route" />

            <LazyHydrate when-visible>
              <CtaPregnant class="cta-pregnant--catalog"></CtaPregnant>
            </LazyHydrate>
          </div>
        </div>
      </div>
    </div>
    <LazyHydrate when-visible>
      <catalogue-porque />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <categories-directory></categories-directory>
    </LazyHydrate>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CatalogueHeroSection from "./components/CatalogueHeroSection";
import CanFilterProducts from "../mixins/CanFilterProducts";
import CatalogueHeader from "./components/CatalogueHeader";
import LazyHydrate from "vue-lazy-hydration";
import DisplayHeader from "../mixins/DisplayHeader";
import CanGetProductsAtServerSide from "../mixins/CanGetProductsAtServerSide";
import CanGetCategories from "../../Mixins/CanGetCategories";
import CatalogueProducts from "./components/CatalogueProducts";
import CatalogueFilters from "./components/CatalogueFilters";
import CatalogueCategoriesNav from "./components/CatalogueCategoriesNav";
import AppliedFilters from "../../components/AppliedFilters";

export default {
  name: "CataloguePage",
  mixins: [
    CanFilterProducts,
    DisplayHeader,
    CanGetCategories,
    CanGetProductsAtServerSide,
  ],
  metaInfo() {
    return {
      title: "Todo lo mejor para tu bebé | Catálogo de HelloBB",
      meta: [
        {
          name: "description",
          content:
            "El catálogo con todo lo mejor para tu bebé. Seleccionamos los mejores objetos y las mejores ofertas de todas las tiendas para que aciertes siempre.",
        },
      ],
      link: [{ rel: "canonical", href: "https://www.hellobb.net/catalogo" }],
    };
  },
  components: {
    CatalogueProducts,
    CatalogueFilters,
    CatalogueCategoriesNav,
    AppliedFilters,
    CtaPregnant: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaPregnant" */ "../cta/CtaPregnant.vue"
      ),
    CatalogueHeroSection,
    // CatalogueHeader: () => import( /* webpackPrefetch: true */ /* webpackChunkName: "CatalogueHeader" */  './components/CatalogueHeader'), SERGI: Dona error! Ni idea pq?
    CatalogueHeader,
    CategoriesDirectory: () =>
      import(
        /* webpackChunkName: "CategoriesDirectory" */
        /* webpackPrefetch: true */ "./components/CategoriesDirectory"
      ),
    CataloguePorque: () =>
      import(
        /* webpackChunkName: "CataloguePorque" */
        /* webpackPrefetch: true */ "./components/CataloguePorque"
      ),
    LazyHydrate,
  },
  data() {
    return {
      orderBy: "relevance",
    };
  },
  computed: {
    ...mapGetters({
      savedOrderBy: "orderBy",
    }),
    parameters() {
      return {
        text: "",
        page: 1,
        minPriority: 90,
        order: this.orderBy,
      };
    },
  },
  mounted() {
    this.cleanSelectedCategory();
  },
  methods: {
    getProducts(server = false) {
      this.orderBy = this.savedOrderBy;
      let parameters = { ...this.parameters, ...{ server: server } };
      parameters = this.applyFilters(parameters, true);
      return this.$store.dispatch("getProducts", parameters);
    },
    cleanSelectedCategory() {
      this.$store.commit("saveSelectedCategory", {
        id: 0,
        name: "",
        level: 0,
        subCategories: [],
        mainCategory: "",
      });
    },
  },
};
</script>
