<template>
  <div class="">
    <div class="bb-hero">
      <div class="bb-hero__content">
        <h1 class="bb-hero__titulo">Inspírate buscando en nuestro catálogo</h1>
        <div class="bb-hero__search">
          <CatalogueSearchBar></CatalogueSearchBar>
        </div>
      </div>

      <div class="bb-hero__image">
        <img loading="eager" src="../../../assets/img/covers/hero-catalogo.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import CatalogueSearchBar from "./CatalogueSearchBar.vue";

export default {
  name: "CatalogueHeroSection",
  components: {
    CatalogueSearchBar,
  },
};
</script>
