import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      products: "products",
      productsPrefetched: "productsPrefetched",
    }),
  },
  methods: {
    getProductsAtServerSide() {
      return this.getProducts(true)
    },
  },
  async serverPrefetch() {
    return await this.getProductsAtServerSide();
  },
  async mounted() {
    if (!this.productsPrefetched) {
      await this.getProducts();
    }
    this.$store.commit("setProductsPrefetched", false); // Restablir products prefetched per permetre navegació client
  }
}